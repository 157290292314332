import { Global, css } from '@emotion/react'
import React, { useContext, useEffect, useState } from 'react'

import LangContext from '../context/LangContext'
import { useWindowHeight } from '../hooks/useWindowDimensions'
import GlobalStyles from '../theme/GlobalStyles'
import Footer from './Footer'
import Nav from './Nav'
import Seo from './Seo'

const Layout = ({ meta, transparentNav, children }) => {
  useEffect(() => {
    const scrollY = window.sessionStorage.getItem('scrollY')
    if (scrollY) {
      window.scrollTo(0, scrollY)
      window.sessionStorage.removeItem('scrollY')
    }
  }, [])
  const langContext = useContext(LangContext)
  useEffect(() => {
    langContext.setLang(meta.lang)
  }, [langContext, meta.lang])
  const viewHeight = useWindowHeight()
  const [vh, setVh] = useState(0)
  useEffect(() => {
    setVh(viewHeight / 100)
  }, [viewHeight])

  return (
    <>
      <GlobalStyles />
      <Global
        styles={css`
          :root {
            --vh: ${vh}px;
          }
        `}
      />
      <Seo meta={meta} />
      <Nav meta={meta} transparent={transparentNav} />
      {children}
      <Footer meta={meta} />
      <div id="lightbox-container" />
    </>
  )
}

export default Layout
