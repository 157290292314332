import { css } from '@emotion/react'
import React from 'react'

import cpc from '../images/logos/cpc.png'
import csc from '../images/logos/csc.svg'
import goles from '../images/logos/goles.svg'
import us from '../images/logos/us.svg'
import { baseGrid, mq } from '../theme/mixins'
import { colors } from '../theme/variables'

const Footer = ({ meta }) => {
  const year = new Date().getFullYear()
  const footer = css`
    ${baseGrid}
    background-color: ${colors.slate};
    color: white;
    h4 {
      font-size: var(--fs-18);
      grid-column: 2 / -2;
      text-align: center;
      margin-top: 2em;
      margin-bottom: 1em;
    }
  `
  const logos = css`
    display: flex;
    flex-wrap: wrap;
    grid-column: 2 / -2;
    justify-content: space-between;
    margin-bottom: calc(var(--pd-sm) - 1rem);
    img {
      height: 9vw;
      opacity: 0.875;
      margin-bottom: 1rem;
      ${mq().small} {
        height: 18vw;
      }
    }
  `
  const legal = css`
    text-align: center;
    background-color: ${colors.slateDark};
    font-size: var(--fs-16);
    padding: 1.5em 0;
    line-height: 1.25;
    grid-column: 1 / -1;
    span {
      font-weight: 700;
    }
    a {
      color: currentColor;
    }
    .recaptcha {
      font-size: var(--fs-14);
      margin-top: 0.75em;
      color: #ffffffaa;
    }
  `
  return (
    <footer css={footer}>
      <h4>{meta.coalition}</h4>
      <div css={logos}>
        <img src={us} alt="University Settlement" />
        <img src={csc} alt="Cooper Square Committee" />
        <img src={cpc} alt="CPC" />
        <img src={goles} alt="Good Old Lower East Side" />
      </div>
      <div css={legal}>
        <div className="copyright">
          {meta.nys} <span>{meta.erap}</span> ©{year}
        </div>
        <div className="recaptcha">
          This site is protected by reCAPTCHA and the Google{' '}
          <a href="https://policies.google.com/privacy">
            Privacy Policy
          </a>{' '}
          and{' '}
          <a href="https://policies.google.com/terms">
            Terms of Service
          </a>{' '}
          apply.
        </div>
      </div>
    </footer>
  )
}

export default Footer
