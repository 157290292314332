import { css } from '@emotion/react'
import { Link } from 'gatsby'
import React, { useContext } from 'react'

import ScrollContext from '../context/ScrollContext'
import useLangLink from '../hooks/useLangLink'
import { absoluteFill, button, mq } from '../theme/mixins'
import { colors } from '../theme/variables'
import LanguageSelector from './LanguageSelector'

const Nav = ({ meta, transparent }) => {
  const prefix = useLangLink(meta.lang)
  const { scroll } = useContext(ScrollContext)
  const nav = css`
    position: fixed;
    width: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    font-size: var(--fs-18);
    color: white;
    box-sizing: border-box;
    transition: background-color 500ms ease;
    background-color: ${transparent ? 'transparent' : colors.slateDark};
    ${mq().medium} {
      flex-wrap: wrap;
      position: absolute;
    }
    ${scroll > 100 &&
    css`
      ${mq('min').medium} {
        background-color: ${colors.slateDark};
      }
    `}
    a {
      position: relative;
      display: inline-block;
      color: currentColor;
      text-decoration: none;
      line-height: 1;
    }
    > div {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 min(2rem, 5vw);
      ${mq().medium} {
        justify-content: center;
      }
      ${mq().small} {
        /* flex-direction: column; */
      }
    }
  `
  const logo = css`
    color: white;
    text-decoration: none;
    margin: 0.5em 2rem;
    padding: 0.5em 0;
    transition: opacity 300ms ease;
    span {
      position: relative;
      z-index: 1;
      &:last-child {
        font-weight: 700;
        display: inline-block;
      }
    }
    &:hover {
      opacity: 0.75;
    }
    ${mq().medium} {
      width: 100%;
      margin: 0 0 0.25em;
      padding: 0.5em 5vw 0.75em;
      text-align: center;
      &:before {
        content: '';
        z-index: 0;
        ${absoluteFill}
        background-color: ${colors.slateDarker};
        opacity: 0.333;
      }
    }
  `
  const links = css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    a.link {
      margin: 0.5rem 2rem;
      padding: 0.5em 0;
      white-space: nowrap;
      &.apply {
        ${button}
        font-size: var(--fs-16);
        &:after {
          display: none;
        }
      }
      &:last-child {
        margin: 0;
      }
      &:after {
        content: '';
        pointer-events: none;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: white;
        left: 0;
        bottom: 0;
        transform: translate3d(0, 0.125em, 0);
        opacity: 0;
        transition: all 100ms ease;
      }
      &:hover {
        color: currentColor;
        &:after {
          opacity: 1;
          transform: translate3d(0, -0.1em, 0);
        }
      }
    }
    ${mq().medium} {
      font-weight: 700;
      justify-content: center;
      flex-wrap: wrap;
    }
  `
  const langStyle = css`
    font-size: var(--fs-16);
    font-weight: 400;
    padding-right: 2rem;
    margin: 0.5rem 0;
    border-right: 1px solid #ffffff88;
    > a {
      margin: 0 0.5em;
      padding: 0.333em 0;
      &.active {
        font-weight: 700;
        text-decoration: none;
        &:after {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
      &:after {
        content: '';
        pointer-events: none;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: white;
        left: 0;
        bottom: 0;
        transform: translate3d(0, 0.25em, 0);
        opacity: 0;
        transition: all 100ms ease;
      }
      &:hover {
        color: currentColor;
        &:after {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
    }
  `
  return (
    <nav css={nav}>
      <Link to={`${prefix}/`} css={logo}>
        <span>{meta.nys}</span> <span>{meta.erap}</span>
      </Link>
      <div>
        <div css={links}>
          <LanguageSelector
            css={langStyle}
            activeLang={meta.lang}
            abbreviated
          />
          <Link className="link" to={`${prefix}/#help`}>
            {meta.helpText}
          </Link>
          <a
            className="apply link"
            href={meta.applyLink}
            target="_blank"
            rel="noreferrer noopener"
          >
            {meta.applyText}
          </a>
        </div>
      </div>
    </nav>
  )
}

export default Nav
