import { useEffect, useState } from 'react'

const isBrowser = typeof window !== `undefined`

function getWindowDimensions() {
  return isBrowser
    ? {
        width: window.innerWidth,
        height: window.innerHeight,
      }
    : { width: 0, height: 0 }
}

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    let requestRunning = false
    const handleResize = () => {
      if (!requestRunning) {
        window.requestAnimationFrame(() => {
          setWindowDimensions(getWindowDimensions())
          requestRunning = false
        })
        requestRunning = true
      }
    }

    const keyboardTriggers = document.querySelectorAll(
      'input, select, textarea'
    )
    if (isBrowser) {
      window.addEventListener('resize', handleResize, { passive: true })
      window.addEventListener('load', handleResize(), { passive: true })
      keyboardTriggers.forEach(trigger => {
        trigger.addEventListener(
          'blur',
          () => setTimeout(handleResize, 100),
          {
            passive: true,
          }
        )
      })

      return () => {
        window.removeEventListener('resize', handleResize, {
          passive: true,
        })
        window.removeEventListener('load', handleResize(), {
          passive: true,
        })
        keyboardTriggers.forEach(trigger => {
          trigger.removeEventListener(
            'blur',
            () => setTimeout(handleResize, 100),
            { passive: true }
          )
        })
      }
    }
  }, [])

  return windowDimensions
}

export const useWindowWidth = () => {
  const { width } = useWindowDimensions()
  return width
}

export const useWindowHeight = () => {
  const { height } = useWindowDimensions()
  return height
}
