export const breakpoints = {
  large: 1440,
  medium: 960,
  small: 540,
}

export const colors = {
  greyLight: '#F0F0F0',
  brick: '#C25154',
  brickDark: '#B54C4E',
  brickDarker: '#A84649',
  brickLight: '#CC6265',
  brickText: '#C73C40',
  slate: '#103D56',
  slateDark: '#0C364C',
  slateDarker: '#18293B',
  tea: '#81B39B'
}
