import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'

const Seo = ({ meta, description, title, image, twitterCard }) => {
  const metaTitle = title || `${meta.erap} | ${meta.nys}`
  const metaDescription = description || meta.description
  return (
    <Helmet
      htmlAttributes={{
        lang: meta.lang,
      }}
      title={metaTitle}
      titleTemplate={title ? `%s | ${meta.erap} | ${meta.nys}` : `%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: twitterCard,
        },
        {
          name: `twitter:creator`,
          content: `${meta.erap} | ${meta.nys}`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `image`,
          property: `og:image`,
          content: image || null,
        },
      ].concat(meta)}
    />
  )
}

Seo.propTypes = {
  description: PropTypes.string,
  image: PropTypes.any,
  meta: PropTypes.shape({
    description: PropTypes.any,
    erap: PropTypes.any,
    lang: PropTypes.any,
    nys: PropTypes.any
  }).isRequired,
  title: PropTypes.string,
  twitterCard: PropTypes.any
}

export default Seo
