import { css } from '@emotion/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React from 'react'

import { colors } from '../theme/variables'

const Article = ({ article }) => {
  const articleStyle = css`
    grid-column: 2 / -2;
    padding: 6rem 0;
    font-size: var(--fs-18);
    h1 {
      font-size: var(--fs-48);
      margin: 0.5em 0;
      line-height: 1.125;
    }
    h2 {
      font-size: var(--fs-30);
      margin: 1em 0 0.5em;
    }
    h3 {
      font-size: var(--fs-21);
    }
    h4,
    h5,
    h6 {
      max-width: 80ch;
    }
    p,
    li {
      max-width: 80ch;
      font-size: inherit;
    }
    table {
      border-collapse: collapse;
      margin: 2em 0;
      width: 100%;
      thead {
        background-color: ${colors.slate};
        color: white;
        th {
          border-right: 1px solid white;
          padding: 0.5em 1em;
          text-align: center;
          &:last-child {
            border-right: none;
          }
        }
      }
      td {
        background-color: ${colors.greyLight};
        border: 1px solid #00000033;
        padding: 0.75em 1em;
        text-align: center;
      }
    }
    .button {
      margin: 2em 0;
      display: block;
      width: max-content;
    }
  `

  return (
    <div css={articleStyle}>
      <MDXRenderer>{article.body}</MDXRenderer>
    </div>
  )
}

export default Article
