import { css } from '@emotion/react'
import { Link } from 'gatsby'
import React from 'react'

const LanguageSelector = ({ activeLang, abbreviated, ...props }) => {
  const getPath = language => {
    if (typeof window !== 'undefined') {
      const pathname = window.location.pathname
      const hash = window.location.hash
      const prefix = language !== 'en' ? `/${language}` : ''
      const sanitized = pathname.split(/\/(?:es|zh)\//g).join('/')
      return `${prefix}${sanitized}${hash}`
    } else return ''
  }
  const setScroll = position => {
    window.sessionStorage.setItem('scrollY', position)
  }
  const selector = css`
    cursor: pointer;
    transition: all 200ms ease;
    color: inherit;
    text-decoration: none;
    &:hover {
      color: inherit;
    }
    &.active {
      cursor: default;
      text-decoration: underline 2px;
    }
  `
  return (
    <div {...props}>
      <Link
        css={selector}
        to={getPath('en')}
        className={activeLang === 'en' && 'active'}
        onClick={() => setScroll(window.scrollY)}
      >
        {abbreviated ? 'EN' : 'English'}
      </Link>
      <Link
        css={selector}
        to={getPath('es')}
        className={activeLang === 'es' && 'active'}
        onClick={() => setScroll(window.scrollY)}
      >
        {abbreviated ? 'ES' : 'Español'}
      </Link>
      <Link
        css={selector}
        to={getPath('zh')}
        className={activeLang === 'zh' && 'active'}
        onClick={() => setScroll(window.scrollY)}
      >
        正體字
      </Link>
    </div>
  )
}

export default LanguageSelector
