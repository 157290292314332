import '../fonts/font-face.css'

import { Global, css } from '@emotion/react'
import emotionNormalize from 'emotion-normalize'
import React from 'react'

import { button } from './mixins'
import { breakpoints, colors } from './variables'

const GlobalStyles = () => {
  const globalStyles = css`
    ${emotionNormalize}
    :root {
      /* Font Sizes */
      --fs-108: min(2.5rem + 7.5vw, 5rem + 3.333vw);
      --fs-60: max(2.75rem + 0.8333vw, 3rem + 1.667vw);
      --fs-48: max(2.25rem + 0.8333vw, 2.5rem + 1.25vw);
      --fs-36: max(2.25rem, 2rem + 0.833vw);
      --fs-30: max(max(2rem, 1.5rem + 0.833vw));
      --fs-24: max(1.75rem, 1.5rem + 0.417vw);
      --fs-21: max(1.667rem, 1.25rem + 0.417vw);
      --fs-18: max(1.333rem, 1rem + 0.417vw);
      --fs-16: 1.333rem;
      --fs-14: 1.167rem;

      /* Font Stacks */
      --heading: 'Avenir Next', 'Open Sans', system-ui, -apple-system,
        'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji';
      --sans-serif: 'Open Sans', system-ui, -apple-system, 'Segoe UI',
        Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
        'Segoe UI Emoji';

      /* Section Padding */
      --pd-sm: clamp(3rem, 2.5vw, 6rem);
      --pd-md: clamp(4.5rem, 5vw, 9rem);
      --pd-lg: clamp(6rem, 7.5vw, 12rem);
      --pd-xl: clamp(12rem, 12.5vw, 18rem);
    }
    html {
      font-size: 12px;
      max-width: 100vw;
      overflow-x: hidden;
      @media (min-width: ${breakpoints.large}px) {
        font-size: calc(9px + 0.21vw);
      }
    }
    body {
      font-family: var(--sans-serif);
    }
    h1 {
      font-family: var(--heading);
      font-size: var(--fs-108);
      font-weight: 600;
      line-height: 1;
    }
    h2 {
      font-family: var(--heading);
      font-size: var(--fs-60);
      font-weight: 600;
      line-height: 1.125;
    }
    h3 {
      font-family: var(--heading);
      font-size: var(--fs-24);
      font-weight: 600;
      line-height: 1.25;
    }
    h4 {
      font-family: var(--sans-serif);
      font-size: var(--fs-18);
      font-weight: 700;
    }
    p {
      line-height: 1.75;
    }
    ul,
    ol {
      margin-top: 1em;
      margin-bottom: 2em;
      li {
        line-height: 1.75;
        margin-bottom: 0.5em;
        p {
          margin: 0;
        }
        ::marker {
          color: ${colors.brickDark};
        }
      }
    }
    ul > li > ul {
      margin-top: 0.5em;
      margin-bottom: 0.75em;
    }
    ol > li {
      ::marker {
        font-weight: 700;
        font-size: 105%;
        content: counter(list-item) '.  ';
      }
      & > ul {
        list-style-type: disc;
        margin-bottom: 1.5em;
        & > li > ul {
          list-style-type: circle;
        }
      }
    }
    a {
      color: ${colors.brickText};
      transition: color 300ms ease;
      &:hover {
        color: ${colors.brickDarker};
      }
    }
    *:-webkit-autofill {
      &,
      &:hover,
      &:focus,
      &:active {
        transition: all 0s 99999s;
        border-radius: 0;
      }
    }
    .grecaptcha-badge {
      visibility: hidden;
    }
    .button {
      ${button}
    }
  `
  return <Global styles={globalStyles} />
}

export default GlobalStyles
