import { css } from '@emotion/react'

import { breakpoints } from './variables'

export const mq = (minMax = 'max') => {
  let bp = Object.create(breakpoints)
  Object.keys(breakpoints).forEach(key => {
    bp[key] = `@media (${minMax}-width: ${breakpoints[key]}px)`
  })
  return bp
}

export const baseGrid = css`
  position: relative;
  display: grid;
  grid-template-columns: 5vw repeat(12, 1fr) 5vw;
  grid-template-rows: auto;
  grid-column-gap: max(2.5vw, 1.5rem);
  width: 100%;
  /* > * {
    grid-column: 1 / -1;
    z-index: 1;
  } */
  ${mq().medium} {
    grid-template-columns: max(2.5vw, 1.5rem) repeat(12, 1fr) max(
        2.5vw,
        1.5rem
      );
  }
  ${mq().small} {
    grid-template-columns: 0 repeat(12, 1fr) 0;
  }
`

export const contentColumn = css`
  position: relative;
  font-size: var(--fs-18);
  grid-column: 2 / -2;
  justify-self: center;
  width: 100%;
  max-width: 80ch;
`

export const absoluteFill = css`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export const button = css`
  display: block;
  cursor: pointer;
  border: 2px solid currentColor;
  background-color: transparent;
  padding: 0.667em 1.333em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 700;
  text-decoration: none;
  transition: color 200ms ease;
  &:hover {
    color: currentColor;
  }
`

export const downArrow = css`
  width: 0.75em;
  height: 0.5em;
  clip-path: polygon(
    50% 60.9%,
    88.3% 3.6%,
    100% 21.3%,
    50% 96.4%,
    0% 21.3%,
    11.8% 3.6%
  );
  background-color: currentColor;
`

export const backArrow = css`
  width: 3.75rem;
  height: 3.75rem;
  clip-path: polygon(
    42.2222222% 1.54154326%,
    1.333% 50%,
    42.2222222% 98.4584567%,
    45.364919% 94.7368421%,
    9.81111111% 52.6310169%,
    100% 52.6315789%,
    100% 47.3684211%,
    9.80888889% 47.367859%,
    45.364919% 5.26315789%
  );
  background-color: currentColor;
`
export const closeX = css`
  width: 3.75rem;
  height: 3.75rem;
  clip-path: polygon(
    93.3682929% 3.48901028%,
    96.5109897% 6.63170709%,
    53.1420103% 50.0000103%,
    96.5109897% 93.3682929%,
    93.3682929% 96.5109897%,
    50.0000103% 53.1420103%,
    6.63170709% 96.5109897%,
    3.48901028% 93.3682929%,
    46.8580103% 50.0000103%,
    3.48901028% 6.63170709%,
    6.63170709% 3.48901028%,
    50.0000103% 46.8580103%,
    93.3682929% 3.48901028%
  );
  background-color: currentColor;
`
